import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { sortBy, path, prop, sortWith, ascend } from 'ramda'
import { Layout } from '../components/Layout'
import { PageHeader } from '../components/Header/PageHeader'
import { H1, SubTitle } from '../components/Title'
import { theme } from '../components/Layout/theme'
import { ContentList } from '../components/ContentList'
import { Global, css } from '@emotion/core'
import SEO from '../components/seo'
import { Container, Row, Column } from '../components/Grid'
import { ButtonLink } from '../components/Button'
import styled from '@emotion/styled'

const ButtonLinkWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`

const MassagesPage = props => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "header-massages.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      massageTypes: allContentfulMassageType {
        nodes {
          id
          name
        }
      }
      massages: allContentfulMassage(
        filter: { title: { ne: "Holistische Massage" } }
      ) {
        nodes {
          id
          title
          duration
          price
          description {
            description
          }
          order
          type {
            id
          }
        }
      }
    }
  `)

  const massageItems = [
    ...sortWith([ascend(prop('order'))])(
      data.massages.nodes.filter(x => x.order),
    ),
    ...sortBy(path(['type', 'id']))(data.massages.nodes.filter(x => !x.order)),
  ]

  return (
    <Layout>
      <Global
        styles={css`
          body {
            background-color: #FBF4F4 };
          }
        `}
      />
      <SEO title="Massages" />
      <PageHeader background={data.background.childImageSharp.fluid}>
        <H1
          textAlign="center"
          color={theme.darkPink}
          style={{ marginBottom: '11px' }}>
          Massages
        </H1>
        <SubTitle textAlign="center" color={theme.brown}>
          Even alles los laten...
        </SubTitle>
      </PageHeader>
      <ContentList items={massageItems} navigation={data.massageTypes.nodes} />
      <Container>
        <Row>
          <Column
            display={{ md: 10 }}
            offset={{ md: 1 }}
            style={{ padding: '30px 0' }}>
            <ButtonLinkWrapper>
              <ButtonLink wide large to="/maak-een-afspraak/">
                Maak een afspraak
              </ButtonLink>
            </ButtonLinkWrapper>
          </Column>
        </Row>
      </Container>
    </Layout>
  )
}
export default MassagesPage
