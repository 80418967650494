import React, { useState } from 'react'
import { Container, Column, Row } from '../Grid'
import { ContentItem } from './ContentItem'
import styled from '@emotion/styled'
import { ContentItemIcon } from './ContentItemIcon'
import { ButtonLink } from '../Button'
import { Paragraph } from '../Text'
import { breakPoints } from '../Layout/theme'

const Wrapper = styled.div`
  margin-top: 45px;
`

const ButtonLinkWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  max-width: calc(100% - 20px);
  margin-top: 30px;
  @media (max-width: ${breakPoints.sm}) {
    margin-bottom: 30px;
  }
`

const NavigationItem = styled.div`
  cursor: pointer;
  font-family: BloggerSans-BoldItalic;
  font-size: 15px;
  color: ${({ active, theme }) => (active ? theme.white : theme.brown)};
  background-color: ${({ active, theme }) =>
    active ? theme.darkPink : theme.white};
  letter-spacing: 0;
  padding: 12px 20px;
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 0 22px 11px;
    border-color: transparent transparent transparent #f78c95;
    position: absolute;
    right: -11px;
    top: 0;
    @media (max-width: ${breakPoints.sm}) {
      display: none;
    }
  }
`

const SubNavigation = styled.ul`
  background-color: ${({ theme }) => theme.white};
  max-width: calc(100% - 20px);
  padding: 0;
  @media (max-width: ${breakPoints.sm}) {
    max-width: none;
  }
`

const ContentList = ({ items = [], navigation = [] }) => {
  const [activeCategory, setActiveCategory] = useState(null)

  const visibleItems = activeCategory
    ? items.filter(i => i.type.id === activeCategory)
    : items

  return (
    <Wrapper>
      <Container>
        <Row>
          {!!navigation.length && (
            <Column display={{ md: 3, xs: 12 }}>
              <SubNavigation>
                <li>
                  <NavigationItem
                    active={!activeCategory}
                    onClick={() => setActiveCategory(null)}>
                    Alle massages
                  </NavigationItem>
                </li>
                {navigation.map(navItem => (
                  <li key={navItem.id}>
                    <NavigationItem
                      active={activeCategory === navItem.id}
                      onClick={() => setActiveCategory(navItem.id)}>
                      {navItem.name}
                    </NavigationItem>
                  </li>
                ))}
              </SubNavigation>

              <ButtonLinkWrapper>
                <ButtonLink wide to="/maak-een-afspraak/">
                  Maak een afspraak
                </ButtonLink>
              </ButtonLinkWrapper>
            </Column>
          )}
          <Column display={{ md: 9, xs: 12 }}>
            {visibleItems.map(item => (
              <>
              <ContentItem
                key={item.id}
                title={item.title}
                description={
                  <Paragraph>{item.description.description}</Paragraph>
                }
                icons={[
                  <ContentItemIcon
                    key={`${item.id}-duration`}
                    label={item.duration}
                    type="duration"
                  />,
                  <ContentItemIcon
                    key={`${item.id}-price`}
                    label={item.price}
                    type="price"
                  />,
                ]}
              />
              </>
            ))}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  )
}

export { ContentList }
